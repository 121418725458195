.outer-circle {
  position: fixed;
  width: 6vw; /* Responsive sizing relative to the viewport width */
  height: 6vw;
  max-width: 120px; /* Ensure it doesn't get too big on large screens */
  max-height: 120px;
  border: 2px solid rgba(255, 255, 255, 0.5); /* Subtle border */
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 40;
  background-color: transparent;
  transition: transform 0.1s ease;
}

.inner-circle {
  position: fixed;
  width: 0.5vw; /* Responsive sizing for the inner circle */
  height: 0.5vw;
  max-width: 20px; /* Cap the size to prevent it from getting too large */
  max-height: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 40;
}
