@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


html, body {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

a, button, [role="button"] {
  cursor: pointer;
  touch-action: manipulation;
}

/* Make sure all interactive elements have sufficient touch target size */
button, a, [role="button"], input, select, textarea {
  min-height: 44px;
  min-width: 44px;
}

/* Ensure proper viewport settings */
@viewport {
  width: device-width;
  initial-scale: 1;
}